<template>
  <div class="page-menu">
    <ul v-if="devicePath === 'web'">
      <li
        v-for="item in gameTypeList"
        :key="item"
        :class="{ active: item === gameType }"
        @click="$emit('handle-click-sidebar', item)"
      >
        {{ $t(`common.gameType.${item}`) }}
        <i v-if="item === gameType" class="fas fa-arrow-circle-right"></i>
      </li>
    </ul>
    <div v-else class="sort-list">
      <button
        @click="handleToggleDropdownMenu"
        :class="activeDropdownMenu ? 'sort-active' : 'collapsed sort-active'"
      >
        {{ $t(`common.gameType.${gameType}`) }}
        <i :class="`fas fa-chevron-${activeDropdownMenu ? 'up' : 'down'}`"></i>
      </button>
      <div id="sort-name" v-if="activeDropdownMenu">
        <ul>
          <li
            v-for="item in gameTypeList"
            :key="item"
            :class="{ active: item === gameType }"
            @click="handleClickDropdownMenu(item)"
          >
            {{ $t(`common.gameType.${item}`) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, inject, ref, reactive, onMounted,
} from 'vue';
import { forEach, find } from 'lodash';
import games from '@/config/games.json';

export default defineComponent({
  emits: ['handle-click-sidebar'],
  setup() {
    const brandConfig = inject('brandConfig');
    const gameType = inject('gameType');
    const devicePath = inject('devicePath');
    const gameTypeList = reactive(['hot', 'frequent']);
    const activeDropdownMenu = ref(false);

    const getGameTypeList = () => {
      forEach(brandConfig.gameList, (game) => {
        const match = find(games.gameList, (item) => item.gameCode === game.gameCode);
        if (match && !gameTypeList.includes(match.gameTypes[0])) {
          gameTypeList.push(match.gameTypes[0]);
        }
      });
    };
    const handleToggleDropdownMenu = () => {
      activeDropdownMenu.value = !activeDropdownMenu.value;
    };
    onMounted(() => {
      getGameTypeList();
    });
    return {
      gameType,
      devicePath,
      gameTypeList,
      activeDropdownMenu,
      handleToggleDropdownMenu,
    };
  },
  methods: {
    handleClickDropdownMenu(item) {
      this.handleToggleDropdownMenu();
      this.$emit('handle-click-sidebar', item);
    },
  },
});
</script>

<style lang="scss" scoped>
.page-menu {
  width: 100%;
  @media (min-width: 1200px) {
    width: 25%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
      display: block;
      padding: 20px;
      color: #999;
      font-size: 20px;
      position: relative;
      transition: 0.3s;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background: linear-gradient(to bottom, #cc00ff, #580f5f);
        transition: all 0.3s ease-in-out 0s;
      }
      &:hover::before {
        width: 100%;
      }
      &:hover,
      &.active {
        color: #000;
      }
      &.active {
        @media (min-width: 768px) {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(to bottom, #cc00ff, #580f5f);
          }
          i {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.sort-list {
  cursor: pointer;
  float: left;
  position: relative;
  .sort-active {
    color: #000;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
  }
  .icon-show {
    display: none;
  }
  .sort-active.collapsed {
    .icon-show {
      display: inline-block;
      transition: 0.6s;
    }
    .icon-close {
      display: none;
      transition: 0.6s;
    }
  }
  #sort-name {
    position: absolute;
    min-width: 150px;
    width: auto;
    left: 0;
    top: 50px;
    z-index: 10;

    background: #fff;
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 8%);
    border-radius: 5px;
    overflow: hidden;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: block;
      padding: 20px;
      color: #999;
      font-size: 20px;
      transition: 0.3s;
      @media (max-width: 480px) {
        padding: 10px 20px;
      }
      &:hover {
        color: #000;
        background: #f1f1f1;
      }
      &.active {
        color: #000;
      }
    }
    li:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>
