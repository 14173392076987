<template>
  <section id="page-banner" :class="`banner-${gameType}`"></section>
  <section class="banner-block">
    <div class="shape-box shape-bottom">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          class="shape-fill"
          d="M0,0 L1000,0 L1000,100 L200,0 L0,50z"
        ></path>
      </svg>
    </div>
  </section>
  <section class="inner-page" v-if="devicePath === 'web'">
    <div class="container">
      <div class="page-title">
        <h1>{{ $t(`common.gameType.${gameType}`) }}</h1>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, inject } from 'vue';

export default defineComponent({
  setup() {
    const gameType = inject('gameType');
    const devicePath = inject('devicePath');
    return {
      gameType,
      devicePath,
    };
  },
});
</script>

<style lang="scss" scoped>
#page-banner {
  min-height: 600px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1920px) {
    min-height: 450px;
  }
  @media (max-width: 991px) {
    min-height: 350px;
    background-position: center;
  }
  &.banner-frequent {
    background-image: url("../assets/images/banner/web-page-banner-frequent.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-frequent.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-frequent.jpg");
    }
  }
  &.banner-hot {
    background-image: url("../assets/images/banner/web-page-banner-hot.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-hot.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-hot.jpg");
    }
  }
  &.banner-lottery {
    background-image: url("../assets/images/banner/web-page-banner-lottery.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-lottery.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-lottery.jpg");
    }
  }
  &.banner-stock {
    background-image: url("../assets/images/banner/web-page-banner-stock.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-stock.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-stock.jpg");
    }
  }
  &.banner-liveStream {
    background-image: url("../assets/images/banner/web-page-banner-liveStream.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-liveStream.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-liveStream.jpg");
    }
  }
  &.banner-rng {
    background-image: url("../assets/images/banner/web-page-banner-rng.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-rng.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-rng.jpg");
    }
  }
  &.banner-ball {
    background-image: url("../assets/images/banner/web-page-banner-ball.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-ball.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-ball.jpg");
    }
  }
  &.banner-graph {
    background-image: url("../assets/images/banner/web-page-banner-graph.jpg");
    @media (max-width: 1300px) {
      background-image: url("../assets/images/banner/pad-page-banner-graph.jpg");
    }
    @media (max-width: 767px) {
      background-image: url("../assets/images/banner/app-page-banner-graph.jpg");
    }
  }
}
.banner-block {
  min-height: 600px;
  @media (max-width: 1920px) {
    min-height: 450px;
  }
  @media (max-width: 991px) {
    min-height: 350px;
  }
  .shape-box.shape-bottom svg {
    height: 150px;
    transform: rotateY(180deg) rotateZ(180deg);
    @media (max-width: 991px) {
      height: 0px;
    }
  }
}
.page-title {
  position: absolute;
  z-index: 10;
  right: 0;
  top: -100px;
  @media (max-width: 991px) {
    display: none;
  }
  h1 {
    color: #000;
    font-size: 72px;
    font-weight: bold;
    text-align: right;
    @media (max-width: 1204px) {
      right: 1.5rem;
    }
  }
}
</style>
