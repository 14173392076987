<template>
  <page-banner />
  <section class="inner-page">
    <div class="container">
      <div class="row">
        <sidebar @handle-click-sidebar="handleClickSidebar" />
        <div class="list">
          <div class="game-list">
            <div
              class="game-card"
              :class="item.gameTypes[0]"
              v-for="item in gameList[gameType]"
              :key="item.gameCode"
            >
              <div class="game-name">
                <img
                  :src="
                    require(`@/assets/images/gameIcon/${item.gameCode}.png`)
                  "
                />
                <h4>{{ $t(`common.gameName.${item.gameCode}`) }}</h4>
              </div>
              <div class="game-caption">
                <ul>
                  <li>
                    <span class="title">{{
                      $t("common.gameInfo.awardPeriod")
                    }}</span>
                    {{ $t(`common.awardPeriod.${item.gameCode}`) }}
                  </li>
                  <li>
                    <span class="title">{{
                      $t("common.gameInfo.betType")
                    }}</span>
                    {{ item.betTypes }}
                  </li>
                  <li v-if="item.bundleTypes.length > 0">
                    <span class="title">{{
                      $t("common.gameInfo.bundleType")
                    }}</span>
                    <span v-for="(type, index) in item.bundleTypes" :key="type">
                      {{ $t(`common.bundleType.${type}`)
                      }}<i v-if="index !== item.bundleTypes.length - 1">, </i>
                    </span>
                  </li>
                  <li>
                    <span class="title">{{
                      $t("common.gameInfo.maxPayout")
                    }}</span>
                    {{ $filters.number(item.maxPayout) }}
                  </li>
                </ul>
              </div>
              <div class="game-btn">
                <button v-if="item.playGame" @click="handlePlayGame(item)">
                  {{ $t("common.playGame") }}
                </button>
                <button
                  :class="{ fullWidth: !item.playGame }"
                  @click="
                    goPage({
                      page: 'Detail',
                      params: { gameName: item.gameName },
                    })
                  "
                >
                  {{ $t("common.detail") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent, ref, reactive, provide, inject, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  forEach, find, upperCase, keys,
} from 'lodash';
import PageBanner from '@/components/PageBanner.vue';
import Sidebar from '@/components/Sidebar.vue';
import games from '@/config/games.json';

export default defineComponent({
  setup() {
    const $route = useRoute();
    const brandConfig = inject('brandConfig');
    const goPage = inject('goPage');
    const handlePlayGame = inject('handlePlayGame');
    const gameType = ref('lottery');
    provide('gameType', gameType);
    const gameList = reactive({});
    const getGameList = () => {
      forEach(brandConfig.gameList, (game) => {
        const match = find(games.gameList, (item) => item.gameCode === game.gameCode);
        if (match) {
          const gameInfo = {
            ...match,
            gameName: game.gameName,
          };
          forEach(match.gameTypes, (item) => {
            if (gameList[item]) {
              gameList[item].push(gameInfo);
            } else {
              gameList[item] = [gameInfo];
            }
          });
        }
      });
    };
    const handleClickSidebar = (item) => {
      gameType.value = item;
      goPage({
        page: 'List',
        params: { gameType: item },
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };
    onMounted(() => {
      getGameList();
      if ($route.params.gameType) {
        const allGameType = keys(gameList);
        const match = find(allGameType, (item) => item === $route.params.gameType);
        if (match) {
          gameType.value = $route.params.gameType;
          window.scrollTo(0, 0);
        } else {
          goPage({
            page: 'List',
            params: { gameType: 'lottery' },
          });
        }
      }
    });
    return {
      upperCase,
      gameType,
      goPage,
      gameList,
      handlePlayGame,
      handleClickSidebar,
    };
  },
  components: {
    PageBanner,
    Sidebar,
  },
});
</script>

<style lang="scss">
.list {
  width: 100%;
  @media (min-width: 1200px) {
    width: 75%;
  }
  .game-list {
    display: flex;
    flex-wrap: wrap;
    .game-card {
      margin: 1rem;
      width: calc(50% - 2rem);
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      @media (max-width: 767px) {
        margin: 1rem auto;
        width: 70%;
      }
      @media (max-width: 600px) {
        width: 90%;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
      &.lottery {
        background-image: url("../assets/images/style-bg/lottery.jpg");
      }
      &.stock {
        background-image: url("../assets/images/style-bg/stock.jpg");
      }
      &.liveStream {
        background-image: url("../assets/images/style-bg/liveStream.jpg");
      }
      &.rng {
        background-image: url("../assets/images/style-bg/rng.jpg");
      }
      &.ball {
        background-image: url("../assets/images/style-bg/ball.jpg");
      }
      &.graph {
        background-image: url("../assets/images/style-bg/graph.jpg");
      }
      .game-name {
        display: flex;
        align-items: center;
        padding: 20px;
        img {
          height: 100px;
          margin: 0 20px;
          -webkit-box-reflect: below 0 -webkit-linear-gradient(rgba(0, 0, 0, 0)
                45%, rgba(0, 0, 0, 0.35));
          @media (max-width: 480px) {
            height: 60px;
          }
        }
        h4 {
          color: #fff;
          font-weight: bold;
          text-shadow: 1px 2px 3px #000;
          margin: 0;
          font-size: 1.5rem;
          @media (max-width: 480px) {
            font-size: 1.25em;
          }
        }
      }
    }
    .game-caption {
      padding: 0 20px 65px;
      ul {
        padding: 10px;
        margin: 0;
        list-style: none;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.65);
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          padding: 5px;
          color: #fff;
          box-sizing: border-box;
          line-height: 1.5;
          .title {
            display: block;
            color: #bbb;
            font-size: 12px;
          }
        }
      }
    }
  }
}

/* list - 試玩、詳細按鈕 */
.game-btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  background: linear-gradient(to bottom, #cc00ff, #580f5f);
  box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.3),
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  button {
    display: inline-block;
    position: relative;
    width: 50%;
    color: #fff;
    text-align: center;
    padding: 10px;
    line-height: 1.5;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.1)
      );
    }
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    &:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
    &:last-child {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
    &.fullWidth {
      width: 100%;
    }
  }
}
</style>
